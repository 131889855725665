import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { defaultLang } from './config-lang';

import enLocales from './langs/en';
import deLocales from './langs/de';
export const getLanguage = (): string => {
  try {
    const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    if (lang === 'en-us') return 'en';
    return lang != null ? lang : defaultLang.value;
  } catch {
    return defaultLang.value;
  }
};
const lng = getLanguage();
void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      de: { translations: deLocales }
    },
    lng,
    fallbackLng: { de: [defaultLang.systemValue] },
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
