export const FACEBOOK = 'facebook';
export const FACEBOOK_GROUP = 'facebook_group';
export const INSTAGRAM = 'instagram';
export const GOOGLE = 'google';
export const TWITTER = 'twitter';
export const LINKEDIN_PERSONAL_ACCOUNT = 'linkedin_personal_account';
export const LINKEDIN_BUSINESS_PAGE = 'linkedin_business_page';
export const TIKTOK_PERSONAL_ACCOUNT = 'tiktok_personal_account';
export const TIKTOK_BUSINESS_PAGE = 'tiktok_business_page';
export const YOUTUBE = 'youtube';
export const WHATSAPP = 'whatsapp';
export const PINTEREST = 'pinterest';
export const GENERIC = 'generic';

export const SELECTED_PLATFORM_COLOR_CODE = {
    [FACEBOOK]: "#3b5998",
    [INSTAGRAM]: "#e4405f",
    [GOOGLE]: "#4285f4",
    [TWITTER]: "#1da1f2",
    [LINKEDIN_PERSONAL_ACCOUNT]: "#0077b5",
    [LINKEDIN_BUSINESS_PAGE]: "#0077b5",
    [TIKTOK_PERSONAL_ACCOUNT]: "#000000",
    [TIKTOK_BUSINESS_PAGE]: "#000000",
    [YOUTUBE]: "#ff0000",
    [FACEBOOK_GROUP]: "#3b5998",
    [WHATSAPP]: "#25d366",
    [PINTEREST]: "#bd081c",
    [GENERIC]: "#7E8299",
}

export const ANALYTICS_COLOR_LINKEDIN_BUSINESS_PAGE = '#0067A1';
export const ANALYTICS_COLOR_FACEBOOK = '#036AF1';
export const ANALYTICS_COLOR_FACEBOOK_GROUP = '#036AF1';

export const ANALYTICS_COLOR_TWITTER = '#00C1F2';
export const ANALYTICS_COLOR_LINKEDIN_PERSONAL_PROFILE = '#A1A5B7';
export const ANALYTICS_COLOR_INSTAGRAM = '#7239ea';
export const ANALYTICS_COLOR_GOOGLE = '#47be7d';
export const ANALYTICS_COLOR_POSTS = '#ffc700';
export const ANALYTICS_COLOR_YOUTUBE = '#f1416c';
export const ANALYTICS_COLOR_TIKTOK_BUSINESS_PROFILE = '#181C32';
export const ANALYTICS_COLOR_TIKTOK_PERSONAL_PROFILE = '#83868D';
export const ANALYTICS_COLOR_PINTEREST = '#A00B1B';

export const ANALYTICS_COLOR_YELLOW = ANALYTICS_COLOR_POSTS;

export const ANALYTICS_COLOR_1 = '#009ef7';
export const ANALYTICS_COLOR_2 = '#A1A5B7';
export const ANALYTICS_COLOR_3 = '#50cd89';
export const ANALYTICS_COLOR_4 = '#7239ea';
export const ANALYTICS_COLOR_5 = '#ffc700';
export const ANALYTICS_COLOR_6 = '#f1416c';
export const ANALYTICS_COLOR_7 = '#181C32';

export const ANALYTICS_COLOR_8 = '#E1E3EA';

export const IMPRESSIONS = 'impressions';
export const REACTIONS = 'reactions';
export const SHARES = 'shares';
export const COMMENTS = 'comments';
export const VIDEO_VIEWS = 'video_views';
export const REACH = 'reach';
export const SHORT_ANALYTICS_METRICS_TITLES = [
  'impressions',
  'reach',
  'reactions',
  'comments',
  'shares'
];

export const PLATFORMS_COLORS = {
  [FACEBOOK]: ANALYTICS_COLOR_FACEBOOK,
  [FACEBOOK_GROUP]: ANALYTICS_COLOR_FACEBOOK_GROUP,
  [INSTAGRAM]: ANALYTICS_COLOR_INSTAGRAM,
  [GOOGLE]: ANALYTICS_COLOR_GOOGLE,
  [TWITTER]: ANALYTICS_COLOR_TWITTER,
  [LINKEDIN_PERSONAL_ACCOUNT]: ANALYTICS_COLOR_LINKEDIN_PERSONAL_PROFILE,
  [LINKEDIN_BUSINESS_PAGE]: ANALYTICS_COLOR_LINKEDIN_BUSINESS_PAGE,
};

export const DETAILS_ANALYTICS_METRICS_ORDER = [
  REACH,
  IMPRESSIONS,
  REACTIONS,
  SHARES,
  COMMENTS
];

export const PLATFORM_LIST = [
  FACEBOOK,
  FACEBOOK_GROUP,
  INSTAGRAM,
  GOOGLE,
  TWITTER,
  LINKEDIN_PERSONAL_ACCOUNT,
  LINKEDIN_BUSINESS_PAGE,
  YOUTUBE,
  TIKTOK_PERSONAL_ACCOUNT,
  TIKTOK_BUSINESS_PAGE,
  WHATSAPP,
  PINTEREST,
  GENERIC
];

export const PLATFORM_LIST_SUPPORTED_IN_COCKPIT = [
    FACEBOOK,
  FACEBOOK_GROUP,
  INSTAGRAM,
  GOOGLE,
  TWITTER,
  LINKEDIN_PERSONAL_ACCOUNT,
  LINKEDIN_BUSINESS_PAGE,
  YOUTUBE,
  TIKTOK_BUSINESS_PAGE,
]

export const UPLOAD_TO_PLATFORM_STATUS_SUCCESS = 3;
export const UPLOAD_TO_PLATFORM_STATUS_FAILED = 4;

export const COCKPIT = 'cockpit';
export const CLOUD = 'cloud';
