/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resolve } from '../../../api/endPoints';
import axiosClient from '../../../utils/axios';
interface locationsList {
  locations: any[];
  currentPageLocations: any[];
  locationsMapping: any;
  filtredLocations: any[];
  rowsNumber: number;
  next: string | null;
  count: number;

  error: boolean | string;
  isLoading: boolean;
}
const initialState: locationsList = {
  locations: [],
  locationsMapping: {},
  currentPageLocations: [],
  filtredLocations: [],
  rowsNumber: 5,
  count: 0,
  next: null,
  error: false,
  isLoading: false
};

const slice = createSlice({
  name: 'locationsList',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setLocations: (state, action: PayloadAction<any>) => {
      state.locations = action.payload.data.results;
      state.currentPageLocations = action.payload.data.results;
      state.next = action.payload.data.next;
      state.count = action.payload.data.count;
    },
    appendLocations: (state, action: PayloadAction<any>) => {
      state.locations.push([...action.payload.data.results]);
      state.next = action.payload.data.next;
      state.count = action.payload.data.count;
    },

    setCurrentPageLocations: (state, action: PayloadAction<any>) => {
      state.currentPageLocations = action.payload;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setRowsNumber: (state, action: PayloadAction<any>) => {
      state.rowsNumber = action.payload;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setFiltredLocations: (state, action: PayloadAction<any>) => {
      state.filtredLocations = action.payload;
    },
    updateLocationsMapping: (state, action: PayloadAction<any>) => {
      const obj = {};
      action.payload.map((location: any) => {
        obj[location.uuid] = location;
        return null;
      });
      state.locationsMapping = JSON.parse(
        JSON.stringify({ ...state.locationsMapping, ...obj })
      );
    }
  }
});

export default slice.reducer;

async function list(url: string, dispatch: any): Promise<any> {
  try {
    const response = await axiosClient.get(url);
    const res = await getNextPage(response);
    response.data.results = res;
    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

export function getLocationsAUserHasAccessTo() {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(slice.actions.startLoading());
      const url = resolve('locations_list', {}, {});
      const data = await list(url, dispatch);
      dispatch(slice.actions.setLocations({ data }));
      dispatch(slice.actions.setFiltredLocations(data.results));
      dispatch(slice.actions.updateLocationsMapping(data.results));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
async function getNextPage(res: {
  data: { results: any[]; next: string };
}): Promise<Array<{ value: BigInteger; date: string }>> {
  let results = res.data.results;
  while (res?.data?.next?.length > 0) {
    res = await axiosClient.get(res.data.next);
    results = results.concat(res.data.results);
  }
  return results;
}
export function getCurrentPageLocations(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    let { rowsNumber, next, locations } = getState().locationsList;
    dispatch(slice.actions.startLoading());
    while (locations.length < rowsNumber * (pageNumber + 1) && next) {
      const data = await list(next, dispatch);
      dispatch(slice.actions.appendLocations(data.results));
      ({ rowsNumber, next, locations } = getState().postsList);
    }
    const p = locations.slice(
      (pageNumber - 1) * rowsNumber,
      pageNumber * rowsNumber
    );
    dispatch(slice.actions.setCurrentPageLocations(p));
    dispatch(slice.actions.stopLoading());
  };
}

export function searchForLocations({ search }: { search: string }) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    const url = resolve(
      'locations_list',
      {},
      {
        search
      }
    );
    const data = await list(url, dispatch);
    dispatch(slice.actions.setCount(data.count));
    dispatch(slice.actions.setLocations({ data }));
    dispatch(slice.actions.stopLoading());
  };
}

export function searchForLocationsOnSlice({ search }: { search: string }) {
  return async (dispatch: any, getState: any) => {
    const locations = getState().locationsList.locations;
    const filteredLocations = locations.filter((location: any) => {
      return location.name.toLowerCase().includes(search.toLowerCase());
    });
    dispatch(slice.actions.setFiltredLocations(filteredLocations));
    dispatch(slice.actions.stopLoading());
  };
}

export function changeRowsNumber(rowsNb: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRowsNumber(rowsNb));
  };
}

export const getSpecificLocation = (locationSlug: any) => {
  return async (dispatch: any) => {
    const location = axiosClient.get(
      resolve('location_details', { locationslug: locationSlug }, {})
    );
    dispatch(slice.actions.updateLocationsMapping([location]));
    dispatch(
      slice.actions.setLocations({
        data: { results: [location], count: 1, next: null }
      })
    );
  };
};
