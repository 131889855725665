/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { generatePath } from 'react-router';
import queryString from 'query-string';
const API_PREFIX = '/api/v1';
const API_PREFIX_V2 = '/api/v2';
const CUMULATED_VALUES_BY_POST = '/analytics/post/:postuid/aggregated';
const CUMULATED_VALUES_BY_LOCATION =
  '/analytics/location/:locationSlug/aggregated';
const POSTS_COUNT_PER_DAY = '/posts/statistics';
const TOP_POSTS = '/images/dashboard/short_post_list';
const POSTS_ANALYTICS = '/images/posts/mulitplatformsocialmediapost';

const POST_ANALYTICS = '/images/posts/mulitplatformsocialmediapost/:postuuid';
// const LOCATION_DETAILS = '/locations/details';

const LOCATION_DETAILS = '/locations/locations/:locationslug';
const LOCATIONS_LIST = '/locations/locations';

const USER_DETAILS = '/users';
const MANAGE_TEAM = '/accounts/team';
const POST_DETAILS_PERIODICALDATA = '/analytics/post/:uuid/periodicaldata';
const POST_DETAILS_AGGREGATED = '/analytics/post/:uuid/aggregated';
const MANAGE_TEAM_MEMBER = '/accounts/team/:uuid';
const MANAGE_INVITES = '/accounts/invites';
const MANAGE_INVITE = '/accounts/invites/:uuid';
const CLOUDS_LIST = '/clouds';
const SUBSCRIPTION_MANAGEMENT_STATUS =
  '/subscription-management/users/team/status';
const COMMUNICATION_CONVERSATION = '/communication/conversation/:uuid';
const COMMUNICATION_MESSAGES = '/communication/messages';
const UPDATE_MESSAGE = '/communication/messages/:uuid';
const MEMBERS_LIST = '/accounts/team/members';
const LABELS_LIST = '/communication/conversation_labels';
const UPDATE_LABEL = '/communication/conversation_labels/:uuid';
const CONVERSATIONS_FILTER = '/communication/conversation_cache';
const CONVERSATIONS_BULK_OPERATION = '/communication/bulk_update';
const endpointsMap: any = {
  cumulated_by_posts: CUMULATED_VALUES_BY_POST,
  cumulated_by_location: CUMULATED_VALUES_BY_LOCATION,
  posts_count: POSTS_COUNT_PER_DAY,
  top_posts: TOP_POSTS,
  posts_analytics: POSTS_ANALYTICS,
  location_details: LOCATION_DETAILS,
  post_details_periodicaldata: POST_DETAILS_PERIODICALDATA,
  post_details_aggregated: POST_DETAILS_AGGREGATED,
  post_analytics: POST_ANALYTICS,
  user_details: USER_DETAILS,
  manage_team: MANAGE_TEAM,
  manage_team_member: MANAGE_TEAM_MEMBER,
  manage_invites: MANAGE_INVITES,
  manage_invite: MANAGE_INVITE,
  locations_list: LOCATIONS_LIST,
  clouds_list: CLOUDS_LIST,
  subscription_management_status: SUBSCRIPTION_MANAGEMENT_STATUS,
  communication_conversation: COMMUNICATION_CONVERSATION,
  communication_messages: COMMUNICATION_MESSAGES,
  update_message: UPDATE_MESSAGE,
  members_list: MEMBERS_LIST,
  labels_list: LABELS_LIST,
  update_label: UPDATE_LABEL,
  conversations_filter: CONVERSATIONS_FILTER,
  conversations_bulk_operation: CONVERSATIONS_BULK_OPERATION
};
const API_V2_LIST = new Set([
  'communication_conversation',
  'communication_messages',
  'update_message',
  'labels_list',
  'conversations_filter',
  'conversations_bulk_operation',
  'update_label'
]);

export const resolve = (
  urlName: string,
  urlParams: object,
  query: object
): string => {
  const prefix = API_V2_LIST.has(urlName) ? API_PREFIX_V2 : API_PREFIX;
  // generate the URL using the urlName, urlParams, query and API_PREFIX
  let url = generatePath(`${prefix}${endpointsMap[urlName]}/`, urlParams);
  if (url.substring(url.length - 1) !== '/') {
    url += '/';
  }

  return Object.keys(query).length > 0
    ? `${url}?${queryString.stringify(query, { arrayFormat: 'comma' })}`
    : url;
};
