import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IsLoadingIndicator = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <FontAwesomeIcon
        icon={solid('circle-notch')}
        size="1x"
        className="fa-spin"
      />
    </div>
  );
};

export default IsLoadingIndicator;
